
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
}
a:hover {
	text-decoration: none;
}
button {
	cursor: pointer;
  margin: 0;
  padding: 0;
	transition: all 250ms linear 0s;
}

	button:disabled,
	button[disabled] {
	border: 1px solid #999999;
	background-color: #cccccc;
	color: #666666;
	}

/* HTML5 display-role reset for older browsers */
/*
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
*/
body {
  font-family: 'Montserrat', sans-serif;
	height: 100%;
}

h2 {
  font-size: 1.625em;
}
h3 {
  font-size: 1.125em;
}
html {
	background: #fff;
	height: 100%;
}
ol, ul, li {
	list-style: none;
}
li, p {
  margin: 1em 0;
}
/*
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
*/


/*--- About ---*/
.about {
	background: url('../images/grupo-ski.jpg') no-repeat;
	background-attachment: fixed;
	background-size: cover;
	color: #fff;
	padding: 3.75em 0;
}
.about_cta {
	display: flex;
	justify-content: center;
	margin: 1.875em 0 0;
}
.about_desc {
	margin: 0.625em 0 0;
}

.about_invite {
	font-size: 0.875em;
	margin: 2.857em 0 0;
}
.about_invite a {
	color: #1eb9eb;
	transition: all 250ms linear 0s;
}
.about_invite a:hover {
	color: #fff;
	text-decoration: none;
}


.additional-list {}

.additional-list li {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 1em 0 0;
}
.additional-list li:first-child {
	margin-top: 0;
}

.additional-list_img {
	border: 1px dashed #c7c7c7;
	display: inline-block;
	height: 2.25em;
	margin: 0 0 0 0.5625em;
	padding: 0.25em;
	text-align: center;
	width: 2.25em;
}
.additional-list_img img {
	max-height: 100%;
	max-width: 100%;
}

.additional-list_info {
	align-items: center;
	display: flex;
	cursor: pointer;
}
.additional-list_name,
.additional-list_price {
	font-size: 0.8125em;
}
.additional-list_name {
	margin: 0 0 0 0.6154em;
}
.additional-list_price {
	margin: 0;
}


/*--- Basket ---*/
.basket {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  font-size: 0.94em;
}

.basket_icon {
  background: #01a7e7;
  border-radius: 23px;
  color: #fff;
  display: inline-flex;
  height: 46px;
  justify-content: center;
  width: 46px;
}
.basket:hover .basket_icon {
  background: #fff;
  border: 2px solid #01a7e7;
  color: #01a7e7;
}

.basket_quantity {
  margin-left: 0.31em;
}
.basket:hover .basket_quantity {
  color: #01a7e7;
}


/*--- Breadcrumbs ---*/
.breadcrumbs {
	display: flex;
	font-size: 0.875em;
	margin: 0;
}
.breadcrumbs > li {
	margin: 0 0 0 1em;
}
.breadcrumbs > li:first-child {
	margin-left: 0;
}
.breadcrumbs a {
	color: #03a1de;
	text-decoration: underline;
}
.breadcrumbs a:hover {
	text-decoration: none;
}


/*--- Buttons ---*/
.button {
  background: #fff;
  border: 2px solid #01a7e7;
  border-radius: 23px;
  color: #01a7e7;
  cursor: pointer;
  font-size: 0.94em;
  height: 46px;
  padding: 0 1.56em;
	transition: all 250ms linear 0s;
}
.button__dark {
  background: #01a7e7;
  color: #fff;
}

.button.button_disabled {
	background: #aaa !important;
	border-color: #aaa !important;
	color: #fff !important;
	cursor: not-allowed;
}
.button.button_disabled:hover {
	color: #fff;
}


.buttonBig {
	background: #01a7e7;
	border: 0 none;
	color: #fff;
	font-size: 0.875em;
	height: 3.571em;
	text-transform: uppercase;
	width: 100%;
}
.buttonBig:disabled,
.buttonBig:disabled:hover {
	background: #aaa;
	cursor: not-allowed;
}
.buttonBig:hover {
	background: #1f76d4;
}


.button.button-sharp {
	background: #01a7e7;
	border-radius: 0;
	border-width: 1px;
	color: #fff;
	font-size: 1em;
	min-width: 12.5em;
	padding: 0 0.625em;
	text-transform: uppercase;
	transition: all 250ms linear 0s;
}
.button-sharp:hover {
	background: none;
	color: #01a7e7;
}
.button-sharp.mod1 {
	border-radius: 0.5em;
	font-size: 0.75em;
	height: 2.25em;
	min-width: 0;
	padding: 0 0.67em;
}
.button-sharp.mod2,
.button-sharp.mod3 {
	border-radius: 0.25em;
	font-size: 0.875em;
	height: 2.5em;
	min-width: 0;
	padding: 0 1.714em;
}
.button-sharp.mod3 {
	background: none;
	border-color: #2d2e3a;
	color: #2d2e3a;
}
.button-sharp.mod3:hover {
	background: #2d2e3a;
	color: #fff;
}

.button.button-less {
	border-radius: 0.333em;
	font-size: 0.75em;
	min-width: 0;
}

.buttonDark {
	background: #01a7e7;
	border: 0;
	color: #fff;
	cursor: pointer;
	height: 1.875em;
	padding: 0 1em;
}
.buttonDark--big {
	font-size: 1.5em;
	padding-top: 0.12em;
}
.buttonDark--mod1 {
	font-size: 1.3em;
	padding-left: 0.7em;
	padding-right: 0.7em;
}

.buttonPlus {
	color: #01a7e7;
	cursor: pointer;
}


.cardForm {
	max-width: 600px;
	padding: 0 15px;
}

.cardForm_bottom {
	align-items: center;
	display: flex;
	margin: 0.3em 0 0;
}
.cardForm_bottom > button {
	margin-left: 1em;
}
.cardForm_bottom > button:first-child {
	margin-left: 0;
}

.cardForm_field {
	margin-bottom: 15px;
}
.cardForm_status {
	margin: 0 0 0 0.1em;
}


/*--- Carousel ---*/
.carousel {
	max-height: 576px;
}
.carousel-main.mod_1 .carousel.carousel-slider {
	max-height: none;
	overflow: visible;
	padding: 0 0 2.5em;
}
.carousel-main .carousel .control-dots .dot {
	background: none;
	border: 2px solid #fff;
	box-shadow: none;
	height: 13px;
	margin: 0 0 0 0.375em;
	opacity: 1;
	width: 13px;
}
.carousel-main .carousel .control-dots .dot:first-child {
	margin-left: 0;
}
.carousel-main.mod_1 .control-dots {
	bottom: 0;
	margin: 0;
}
.carousel-main.mod_1 .control-dots .dot {
	background: #01a7e7;
	border: 0 none;
	margin-left: 0.7em;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
	background: #ffe49d;
	border-color: #ffe49d;
}
.carousel-main.mod_1 .carousel .control-dots .dot.selected,
.carousel-main.mod_1 .carousel .control-dots .dot:hover {
	background: #0f0;
}


/*--- Cart page ---*/
.cartPage .cartSummary {
	margin: 2px 0 0;
}
.cartPage_addExtra {
	margin: 0;
	font-size: 0.875em;
	font-style: italic;
	padding: 0 0.5em 0.5em;
}
.cartPage_addExtra_link,
.cartPage_addExtra_link:hover {
	color: #359AF8;
}
.cartPage_addExtra_link {
	font-weight: 700;
	text-decoration: underline;
}
.cartPage_buttons {
	text-align: center;
}
.cartPage_buttons .buttonDark {
	margin-top: 1em;
}
.cartPage_cnt {
	margin-top: 2px;
	padding: 0 0 1em;
}
.cartPage_cnt .responsiveList li {
	border-top: 1px solid #3F3572;
	padding: 1em 0;
}
.cartPage_cnt .responsiveList li:first-child {
	border-top: 0 none;
}
.cartPage_cnt .rollet {
	margin: 2px 0 0;
}

.cartPage_delivery {
	margin: 1em 0 0 !important;
}
.cartPage_delivery .fDelivery {
	background: #fff;
	padding: 15px;
}
.cartPage_delivery_cnt {
	padding: 1em;
}


/*--- CartSummary ---*/
.cartSummary,
.deliverTo {
	background: #2F3847;
	color: #fff;
	font-size: 0.875em;
	font-weight: 700;
}
.cartSummary {
	padding: 0.5em 0;
}

.cartSummary_clear {
	color: #f00;
	cursor: pointer;
	text-decoration: underline;
}
.cartSummary_clear:hover {
	text-decoration: none;
}

.cartSummary_col--last {
	text-align: right;
}


/*--- Checkbox ---*/
.checkbox {
	align-items: center;
	display: flex;
}
.checkbox_label {
	margin: 0 0 0 0.5em;
}


/*--- Checklist ---*/
.checklist {}

.checklist_item {
	display: flex;
	font-size: 0.9375em;
	margin: 1em 0 0;
}
.checklist_item:first-child {
	margin-top: 0;
}

.checklist_item > label {
	align-items: center;
	display: flex;
}
.checklist_item_color {
	display: inline-block;
	border: 1px solid #ddd;
	border-radius: 50%;
	height: 1em;
	margin: 0 0 0 0.7em;
	width: 1em;
}
.checklist_item_img {
	height: 1.786em;
	margin: 0 0 0 0.6em;
	width: 1.786em;
}
.checklist_item_label {
	color: #8d8d8d;
	line-height: 1;
	margin: 0 0 0 0.643em;
}


/*--- Clearer ---*/
.clearer {
	padding: 1.5625em 0 0;
	position: relative;
}
.clearer_clear {
	color: #01a7e7;
	cursor: pointer;
	font-size: 0.75em;
	position: absolute;
	right: 0;
	text-decoration: underline;
	top: 0;
}
.clearer_clear:hover {
	text-decoration: none;
}


/*--- ConfirmForm ---*/
.confirmForm {}
.confirmForm_product {
	margin: 3em 0 0;
}
.confirmForm_product > .row {
	margin: 0;
}
.confirmForm_product--first {
	margin-top: 1em;
}
.confirmForm_button {
	margin: 2em 0 0;
	text-align: center;
}
.confirmForm_product_hd {
	font-size: 2em;
}
.confirmForm_product_image img {
	max-width: 100%;
}
.confirmForm_product_sec {
	padding: 0;
}


/*--- DeliverTo ---*/
.deliverTo {
	padding: 1.2em 0 0.75em;
}
.deliverTo_cnt {
	align-items: center;
	display: flex;
}


.rc-checkbox {
	border: 1px solid #01a7e7;
	display: inline-block;
	height: 1em;
	position: relative;
	width: 1em;
}
.rc-checkbox.rc-checkbox-checked {
	background: #01a7e7 url('../images/checked.svg') no-repeat 50% 50%;
}
.rc-checkbox-input {
	cursor: pointer;
	height: 100%;
	opacity: 0;
	position: absolute;
	width: 100%;
}


/*--- datepicker ---*/
.datepicker {
	position: relative;
}
.datepicker input {
	cursor: pointer;
}

.datepicker .react-datepicker {
	font-family: 'Montserrat', sans-serif;
	font-size: 0.875em;
}
.datepicker .react-datepicker--time-only {
	display: block;
}
.datepicker .react-datepicker__day--selected,
.datepicker .react-datepicker__day--selected:hover,
.datepicker .react-datepicker__day--in-selecting-range,
.datepicker .react-datepicker__day--in-selecting-range:hover,
.datepicker .react-datepicker__day--in-range,
.datepicker .react-datepicker__day--in-range:hover {
	background: #01a7e7;
}
.datepicker .react-datepicker__day--today,
.datepicker .react-datepicker__day--today:hover {
	background: #fde19a;
	background-image: linear-gradient(to bottom,#fdd49a,#fdf59a);
	border-radius: 0.3rem;
	font-weight: normal;
}

.datepicker .react-datepicker__header {
	background: none;
	border-bottom: 0 none;
}
.datepicker .react-datepicker__header--time {
	border-bottom: 1px solid #aeaeae;
}

.datepicker .react-datepicker__input-container,
.datepicker .react-datepicker-wrapper {
	display: block;
}
.datepicker .react-datepicker__triangle {
	display: none;
}
.datepicker .react-datepicker-popper {
	right: 0;
}
.datepicker .react-datepicker-popper[data-placement^="bottom"] {
	margin-top: 0.125em;
}
.datepicker .react-datepicker-popper[data-placement^="top"] {
	margin-bottom: 0.125em;
}
.datepicker .react-datepicker__time-box {
	width: auto !important;
}
.datepicker .react-datepicker__time-container {
	float: none;
	width: auto;
}


.dropdown {
	display: flex;
	position: relative;
}

.dropdown_children {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	z-index: 999;
}

.dropdown_children_cnt {
	left: 0;
	position: absolute;
	top: 0;
}


.dropdownToggler {
	cursor: pointer;
	display: inline-block;
	padding: 0 1.3em 0 0;
	position: relative;
}
.dropdownToggler_icon {
	align-content: center;
	bottom: 0;
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
}
.dropdownToggler_icon_img {
	color: #01a7e7;
}


/*--- Form Delivery ---*/
.fDelivery {
	padding: 0 15px;
}
.fDelivery .iconed_label {
	font-weight: normal;
	text-transform: uppercase;
}
.fDelivery > .row > .col-12 {
	margin-top: 0.5em;
}
.fDelivery > .row > .col-12:first-child {
	margin-top: 0;
}


/*--- Form search on the Main page ---*/
.f-search-main {
	background: #fff;
  border-radius: 25px;
	box-shadow: 0 0 0 5px rgba(50, 63, 81, 0.3);
  padding: 0 58px 0 25px;
  position: relative;
}

.f-search-main__button {
  align-items: center;
  background: none;
  border: 0 none;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 0;
  width: 50px;
}
.f-search-main__button:before {
  border-left: 1px solid #ccc;
  bottom: 11px;
  content: '';
  left: -1px;
  position: absolute;
  top: 11px;
}

.f-search-main__img {
	height: 100%;
	width: 100%;
}
.f-search-main__input {
  border: 0 none;
  border-radius: 0;
	color: #a0a0a0;
  height: 50px;
  padding-left: 0;
  padding-right: 0;
}
.f-search-main__warn {
	align-items: center;
	color: #f00;
	cursor: default;
	display: flex;
	font-size: 0.75em;
	height: 100%;
	padding: 0 0.5em 0 0;
	position: absolute;
	right: 55px;
	top: 0;
}


/*--- Form search 2 ---*/
.f-search2 {
	background: #16436499;
	border: 1px solid #ffffff40;
	border-radius: 2px;
	color: #fff;
	padding: 0 2.13em 0 0;
	position: relative;
}
.f-search2 ::placeholder {
	color: #fff;
	opacity: 1;
}

.f-search2_btn {
	align-items: center;
	background: none;
	border: 0 none;
	bottom: 0;
	display: flex;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 2.125em;
}

.f-search2_input,
.f-search2_input:focus {
	background: none;
	color: #fff;
}
.f-search2_input {
	border: 0 none;
	border-radius: 0;
	font-size: 0.75em;
	height: 2.84em;
}


/*--- Form search 3 ---*/
.f_search3 {
	position: relative;
}
.f_search3_button {
	align-items: center;
	background: none;
	border: 0 none;
	cursor: pointer;
	display: flex;
	height: 100%;
	justify-content: center;
	position: absolute;
	right: 0;
	top: 0;
	width: 2em;
}
.f_search3_input {
	margin: 0 2em 0 0;
}


/*--- SearchExtended ---*/
.f-search-extended {
	padding: 15px 22px 7px;
}
.f-search-extended input,
.f-search-extended .Select {
	font-size: 0.875em;
}
.f-search-extended .row {
	margin-top: 1.25em;
}
.f-search-extended--no-mt {
	margin-top: 0 !important;
}
.f-search-extended_button {
	display: flex;
	justify-content: center;
	margin-top: 2em;
}


/*--- FieldsSet ---*/
.fields_set {}
.fields_set_fields {
	padding: 1em 0 0;
	position: relative;
}
.fields_set_fields li {
	margin: 0 0 0.9375em !important;
	position: relative;
}
.fields_set_fields li.has_tip {
	margin-bottom: 0.3125em !important;
}
.fields_set_fields_checkboxes {
	display: flex;
	flex-wrap: wrap;
}
.fields_set_fields_checkboxes li {
	margin: 0 0 0.5em;
	width: 50%;
}
.fields_set_fields_controls {
	display: flex;
}
.fields_set_fields_controls_input {
	width: 100%;
}
.fields_set_fields_controls.has_units .fields_set_fields_controls_input {
	width: 74%;
}
.fields_set_fields_controls_units {
	width: 26%;
}
.fields_set_fields_controls_units_in {
	margin: 0 0 0 0.9375em;
}
.fields_set_fields_error {
	bottom: -15px;
	color: #f00;
	font-size: 0.75em;
	left: 0;
	position: absolute;
}
.fields_set_fields_tip {
	font-size: 0.875em;
	margin: 0;
	text-align: right;
}
.fields_set_fields_tip_txt {
	color: #01a7e7;
	cursor: pointer;
}
.fields_set_fields_toggle {
	margin: 0;
	position: absolute;
	right: 0;
	top: 0;
}
.fields_set_hd {
	color: #3d3d3d;
	font-size: 0.75em;
	margin: 0;
	text-align: center;
}


.fileInput {
	cursor: pointer;
	display: inline-block;
	position: relative;
}
.fileInput_disabled {
	cursor: not-allowed;
}
.fileInput_input {
	bottom: 0;
	cursor: pointer;
	left: 0;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 100%;
}
.fileInput_input_field {
	cursor: pointer;
	height: 100%;
	width: 100%;
}


/*--- Footer ---*/
.footer {
	background: url('../images/footer-_bg.jpg') no-repeat 50% 100%;
	box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.13);
	padding: 3.4375em 0 0;
}
.footer-bottom {
	background: #00365b;
	color: #a0bfd4;
	font-size: 0.75em;
	line-height: 1.2;
	padding: 1.41em 0;
	text-align: center;
}
.footer .footer-bottom {
	margin: 3.75em 0 0;
}
.footer-bottom a {
	color: inherit;
}
.footer-bottom a:hover {
	color: #01a7e7;
	text-decoration: none;
}

.footer-top {}
.footer-top .info-list {
	margin: 1.375em 0 0;
}
.footer-top .list-social {
	margin: 2.5em 0 0;
}

.form-control:disabled {
	cursor: not-allowed;
}
.form-control:focus {
	box-shadow: none;
}

.form-control-status {
	align-items: center;
	bottom: 0;
	cursor: pointer;
	display: flex;
	font-size: 0.8em;
	padding: 0 0.5em 0 0;
	position: absolute;
	right: 0;
	top: 0;
}
.form-control-status.bottom {
	bottom: auto;
	top: 100%;
}
.form-control-status.error {
	color: #f00;
}


/*--- Geosuggest ---*/
.geo-suggest-input {
	position: relative;
}
.geo-suggest-input .form-control-status {
	height: 2.969em;
	top: auto;
}

.geosuggest__input {
	border-radius: 0;
}

.geosuggest__item {
	border-top: 1px solid #ddd;
	cursor: pointer;
	font-size: 0.75em;
	margin: 0;
	padding: 0.7em 1em;
}
.geosuggest__item:first-child {
	padding-top: 0.8em;
}
.geosuggest__item:hover {
	background: #eee;
}

.geosuggest__item--active {
	background: #267dc0;
	color: #fff;
}
.geosuggest__item__matched-text {
	font-weight: 700;
}

.geosuggest__suggests {
	background: #fff;
	border: 1px solid #ddd;
	border-top: 0 none;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.geosuggest__suggests-wrapper {
	position: relative;
	z-index: 1;
}
.geosuggest__suggests--hidden {
	border-width: 0;
	max-height: 0;
	overflow: hidden;
}


/*--- Headings ---*/
.hd-cap,
.hd-cap2 {
	text-transform: uppercase;
}
.hd-cap {
	font-size: 2.375em;
}

.hd-cap2 {
	color: #03a1de;
	font-family: 'Poppins', sans-serif;
	font-size: 1.25em;
	font-weight: 600;
	line-height: 1.1;
	padding: 0 0 0.5em;
	position: relative;
}

.hd-mountain {
	color: #004270;
	font-size: 2.1875em;
	padding: 1.14em 0 0;
	position: relative;
	text-align: center;
}
.hd-mountain:before {
	background: url('../images/grey.svg') no-repeat 0 0;
	background-size: contain;
	content: '';
	height: 35px;
	left: 50%;
	margin: 0 0 0 -44px;
	position: absolute;
	top: 0;
	width: 89px;
}

.hd-mountain-dark {
	color: #00365b;
}
.hd-mountain-dark:before {
	background-image: url('../images/blue.svg');
}

.hd-page {
	color: #03a1de;
	font-family: 'Poppins', sans-serif;
	font-size: 2em;
	font-weight: 400;
	padding: 0 0 0.25em;
	position: relative;
	text-transform: capitalize;
}
.hd-page:after,
.hd-cap2:after {
	border-top: 0.15em solid #8ccffd;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	width: 1.6em;
}
.hd-page:after {
	border-top-width: 0.09375em;
}

.hd-title {
	font-family: 'Montserrat Black', sans-serif;
  font-size: 4.0625em;
  font-weight: 700;
}
.hd-title2 {
	color: #01a7e7;
	font-size: 1.2em;
	font-weight: 700;
	margin: 0;
}


.headedForm {
	border: 1px solid #ddd;
	border-radius: 0.25em;
	padding: 0 0.9375em 0.9375em;
}
.headedForm_btn {
	margin: 1.25em 0 0;
}
.headedForm_field {
	margin: 1.25em 0 0;
}

.headedForm_statusMsg {
	font-size: 0.85em;
	margin: 0.5em 0 0;
}
.headedForm_statusMsg_error {
	color: #f00;
}
.headedForm_statusMsg_success {
	color: #00ba00;
}

.headedForm_title {
	border-bottom: 1px solid #ddd;
	color: #333;
	font-weight: 500;
	padding: 0.625em 0.9375em;
	margin: 0 -0.9375em;
	text-transform: capitalize;
}
.headedForm_title + .headedForm_field {
	margin-top: 0.9375em;
}


/*--- Header ---*/
.header {
	padding-bottom: 0.625em;
	padding-top: 0.625em;
}
.header .logo-cnt {
	float: left;
}
.header-cnt {
	margin: 0	-15px;
}
.header-cnt:after {
	clear: both;
	content: '';
	display: block;
	height: 0;
	visibility: hidden;
	width: 0;
}
.header-info {
	display: flex;
	float: right;
	margin: 4px 0 0;
}
.header-info .header-info-basket {
	border-right: 1px solid #dbdbdb;
	margin: 0 1.4em 0 0;
	padding: 0 1.2em 0 0;
}
.header-info .header-info-btn {
	margin: 0 0 0 0.8em;
}
.header-info .header-info-btn--loggedIn {
	border-left: 1px solid #dbdbdb;
	margin-left: 1em;
	padding: 0 0 0 1em;
}
.header-info-btn .menu > li {
	white-space: nowrap;
}


/*--- iconed ---*/
.iconed {
	padding: 0 0 0 2.375em;
	position: relative;
}
.iconed-wrap {}

.iconed_clear {
	color: #01a7e7;
	cursor: pointer;
	margin: -0.4375em 0 0;
	position: absolute;
	right: -1em;
	top: 50%;
}
.iconed_clear:hover {
	color: #f00;
}

.iconed_icon {
	align-items: center;
	background: #eee;
	border: 1px solid #ced4da;
	border-bottom-left-radius: 0.25em;
	border-top-left-radius: 0.25em;
	border-right: 0	none;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 2.375em;
}
.iconed_icon img {
	max-height: 100%;
	max-width: 45%;
}

.iconed_label {
	font-size: 0.875em;
	font-weight: 700;
}

.iconed .form-control,
.iconed .Select-control {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0.25em;
	border-top-right-radius: 0.25em;
}


.imageRound {
	background-position: 50% 50%;
	background-size: cover;
	border: 1px solid #01a7e7;
	border-radius: 50%;
	display: inline-block;
}


/*--- InfoList ---*/
.info-list {
	display: flex;
	justify-content: center;
}
.info-list li {
	border-left: 1px solid #98c1dc;
	color: #00253d;
	font-size: 0.875em;
	font-weight: 400;
	line-height: 1.3;
	margin: 0 0 0 1.428em;
	padding: 0 0 0 1.428em;
	text-transform: uppercase;
}
.info-list li:first-child {
	border-left: 0 none;
	margin-left: 0;
	padding-left: 0;
}

.info-list li a {
	color: #00253d;
}
.info-list li a:focus,
.info-list li a:hover {
	color: #01a7e7;
	text-decoration: none;
}


/*--- InnerPage ---*/
.inner-banner {
	background: url('../images/search-page-banner.jpg') no-repeat 50% 50%;
	background-size: cover;
	color: #fff;
}
.inner-banner_bg {
	background: #00365ba6;
}
.inner-banner_cnt {
	align-items: center;
	min-height: 8.875em;
}
.inner-banner_hd {
	font-size: 1.375em;
	font-weight: 700;
}

.inner-page__cnt {
	padding-bottom: 5em;
	padding-top: 3.375em;
}

.inner-page_search {
	display: flex;
	justify-content: flex-end;
	width: 100%;
}
.inner-page_search .f-search2 {
	width: 18.75em;
}


.levels-list {
	display: flex;
}
.levels-list img {
	display: block;
	height: 1.5625em;
	width: 1.5625em;
}

.levels-list li {
	margin: 0 0 0 0.25em;
}
.levels-list li:first-child {
	margin-left: 0;
}


/*--- Input text control ---*/
.input-text {}
.input-text_cnt {
	position: relative;
}
.input-text_required {
	color: #f00;
	margin: 0 0 0 0.2em;
}
.geo-suggest-input.mod1 label,
.input-text--mod1 label {
	color: #7c7c7c;
}


/*--- ListSocial ---*/
.list-social {
	display: flex;
	justify-content: center;
}
.list-social li {
	margin: 0 0 0 22px;
}
.list-social li:first-child {
	margin-left: 0;
}


/*--- Logo ---*/
.logo {
  max-height: 60px;
}


/*--- LookingIn ---*/
.looking-in {
	color: #8d8d8d;
	font-size: 0.9375em;
	line-height: 1.5;
	text-align: center;
}
.looking-in_place {
	color: #01a7e7;
	cursor: pointer;
	display: inline-block;
	margin: 0.665em 0 0;
	text-transform: uppercase;
}


/*--- Main ---*/
.main-right {}


/*--- MainOffer ---*/
.main-offer {
  color: #fff;
  text-align: center;
}

.main-offer__description {
  font-size: 1.625em;
  font-weight: 300;
  margin: 0.38em 0 0;
	line-height: 1.1;
}

.main-offer__heading {
  line-height: 0.9;
}

.main-offer__options {
  display: flex;
  font-size: 1.125em;
  justify-content: center;
  margin-top: 1.11em;
}
.main-offer__options__marked {
  margin-left: 0.3em;
  padding-left: 0.7em;
  position: relative;
}
.main-offer__options__marked:before {
  bottom: 0;
  content: '-';
  left: 0;
  position: absolute;
  top: 0;
}
.main-offer__options__emph {
  color: #ffe49d;
}


/*--- ManageResults ---*/
.manageResults {
	padding: 0.5em 15px;
	text-align: center;
}
.manageResults .f_search3 {
	padding: 0.5em 0;
	width: 100%;
}
.manageResults_filters {
	cursor: pointer;
}

.manageResults--wrap {
	border-bottom: 1px solid #01a7e7;
}


.menu {
	background: #fff;
	border: 1px solid #ccc;
	box-shadow: 0 0.429em 0.857em rgba(0, 0, 0, .175);
	font-size: 0.875em;
	padding: 0.357em 0;
}
.menu > li {
	margin: 0;
}
.menu > li > a {
	color: #000;
	display: block;
	width: max-content;
}
.menu > li.menu_item_chosen,
.menu > li.menu_item_noAncor,
.menu > li > a {
	padding: 0.358em 0.715em;
}
.menu > li.menu_item_chosen,
.menu > li.menu_item_lighted > a,
.menu > li > a:hover {
	color: #01a7e7;
}
.menu > li.menu_item_chosen,
.menu > li > a:hover {
	background: #e5e5e5;
}


/*--- MainOffer block ---*/
.mob {
	position: relative;
}
.mob_info {
	align-items: center;
	bottom: 25px;
	display: flex;
	justify-content: center;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}
.mob_info .f-search-main {
	margin: 1em 0 0;
}


/*--- Modal ---*/
.modall {
	background: #fff;
	border: 2px solid #01a7e7;
	padding: 1.5em 1.5em 1em;
	position: relative;
}
.modall .cardForm {
	padding-left: 0;
	padding-right: 0;
}

.modall_close {
	color: #01a7e7;
	cursor: pointer;
	position: absolute;
	right: 0.2em;
	top: 0.2em;
}


/*--- MoreResults ---*/
.moreResults {
	display: flex;
	font-size: 0.875em;
	justify-content: center;
	text-align: center;
	text-transform: uppercase;
}
.moreResults_text {
	color: #01a7e7;
	cursor: pointer;
	padding: 0 0 40px;
	position: relative;
}
.moreResults_text:after {
	background: url('../images/arrow-down.png') no-repeat;
	bottom: 0;
	content: '';
	height: 20px;
	left: 50%;
	margin: 0 0 0 -20px;
	position: absolute;
	width: 40px;
}
.moreResults_text:hover {
	color: inherit;
}


/*--- Margins ---*/
.mt_22 {
	margin-top: 1.375em;
}


.notFound {
	text-align: center;
}


.notification {}
.notification_icon {
	color: #646464;
}
.notification .card-body {
	font-size: 0.9em;
	padding: 1em;
}
.notification .card-header {
	align-items: center;
	color: #C2ADA4;
	display: flex;
	font-size: 0.85em;
	justify-content: space-between;
	padding-left: 1em;
	padding-right: 1em;
	text-align: right;
}


.notifications {
	align-content: center;
	border: 2px solid #01a7e7;
	border-radius: 50%;
	cursor: pointer;
	display: inline-flex;
	height: 46px;
	justify-content: center;
	position: relative;
	width: 46px;
}
.notifications:hover {
	background: #01a7e7;
}

.notifications_icon {
	color: #01a7e7;
}
.notifications:hover .notifications_icon {
	color: #fff;
}

.notifications_list {}
.notifications_list.responsiveList > li {
	margin-top: 2em;
}
.notifications_list.responsiveList > li:first-child {
	margin-top: 0;
}

.notifications_quantity {
	background: #0f0;
	border-radius: 30%;
	color: #fff;
	font-size: 0.8em;
	font-weight: 700;
	padding: 0.1em 0.5em;
	position: absolute;
	right: -0.5em;
	top: -0.5em;
}


.notificationsPage {}
.notificationsPage_cnt {
	margin: 1em 0 0;
}
.notificationsPage_paginator {
	margin: 2em 0 0;
}
.notificationsPage_tip {
	font-size: 1.125em;
	font-weight: 500;
}

.modal_search{
	margin-top: 20px;
	margin-bottom: 20px;
}

/*--- Overlay ---*/
.overlay {
	align-items: center;
	bottom: 0;
	display: flex;
	justify-content: center;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 9999;
}
.overlay--full_width {
	width: 100%;
}
.overlay .modall {
	margin: 0 auto;
}
.overlay_children {
	position: relative;
	z-index: 1;
}
.overlay_children--full_width {
	margin: 0 15px;
}
.overlay_curtain {
	content: '';
	height: 100%;
	left: 0;
	opacity: 0.5;
	position: absolute;
	top: 0;
	width: 100%;
}


.p-description {
	font-size: 1em;
	line-height: 1.75;
}


/*--- Page ---*/
.page {
	background: #fff;
	display: table;
	height: 100%;
	width: 100%;
}
.page-content {
	display: table-row;
	height: 100%;
}


/*--- PageConfirm ---*/
.pageConfirm {}
.pageConfirm .breadcrumbs {
	margin: 1em 0 0;
}
.pageConfirm .confirmForm {
	margin: 2em 0;
}


.paginator {
	text-align: center;
}
.paginator_wide {
	align-items: center;
	display: flex;
	justify-content: space-between;
}
.paginator_count {
	font-size: 0.9em;
}

.paginator_list {
	display: flex;
	margin: 0.5em 0 0;
}
.paginator_wide .paginator_list {
	margin-top: 0;
}

.paginator_list > li {
	font-size: 0.8em;
	margin: 0 0 0 0.5em;
	text-align: center;
}
.paginator_list > li:first-child {
	margin-left: 0;
}
.paginator_list > li > a {
	align-items: center;
	border: 1px solid #00000020;
	border-radius: 0.2em;
	cursor: pointer;
	display: flex;
	height: 3.125em;
	justify-content: center;
	min-width: 3.125em;
	padding: 0 0.5em;
}
.paginator_list > li > a:hover {
	background: #eee;
}
.paginator_list > li.selected > a {
	background: #01a7e7;
	color: #fff;
	cursor: default;
}


.passwordResetPage {
	padding-top: 1.875em;
}
.passwordResetPage_cnt {
	margin: 0 auto;
	max-width: 400px;
}


/*--- PlusList ---*/
.plus-list {}
.plus-list li {
	align-items: center;
	display: flex;
	margin: 0.2em 0 0;
}
.plus-list li:first-child {
	margin-top: 0;
}
.plus-list .plus-list_icon {
	margin: 0 1em 0 0;
}


/*--- Point ---*/
.point {
	background: #0000008c;
	color: #fff;
	font-size: 0.9375em;
	line-height: 28px;
	min-height: 220px;
	padding: 20px;
	text-align: center;
	text-transform: capitalize;
}
.point-image {
	display: block;
	height: 60px;
	margin: 0 auto;
	width: 60px;
}


/*--- PointList ---*/
.point-list {}
.point-list li {
	margin: 0;
}
.about .point-list {
	margin-top: 1.875em;
	padding-left: 0;
	padding-right: 0;
}
.point-list li:first-child {
	margin-top: 0;
}
.point-list .point {
	height: 100%;
	margin: 0 auto;
}


/*--- Price ---*/
.price {
	color: #000;
	font-size: 1.5em;
	margin: 0;
}
.price_value {
	color: #01a7e7;
}


/*--- Product ---*/
.product {
	border: 2px solid #01a7e7;
}
.product_additional_hd {
	color: #01a7e7;
	font-size: 0.75em;
	font-weight: 500;
	margin: 0.5em 0 0;
	text-align: center;
}
.product_body {
	padding: 0.4375em 0.3125em 0.3125em;
}
.product_brand {
	font-size: 0.9375em;
	line-height: 1.1;
}
.product_footer {
	align-items: center;
	border-top: 2px solid #01a7e7;
	display: flex;
	justify-content: space-between;
	padding: 0.3125em 0.625em 0.3125em 0.3125em;
}

.product_image {
	margin: 1.25em 0 0;
	text-align: center;
}
.product_image img {
	max-height: 18.75em;
	max-width: 100%;
}

.product_info {
	display: flex;
	justify-content: flex-end;
}

.product_price {
	align-items: center;
	color: #000;
	display: flex;
	font-size: 0.8125em;
	font-weight: 500;
}
.product_price-total {
	color: #01a7e7;
	font-size: 1.5385em;
	margin: 0 0 0 0.25em;
}

.product_title {
	background: #01a7e7;
	color: #fff;
	text-align: center;
}
.product_title h1 {
	font-size: 1.25em;
}
.product_title--detailed {
	padding: 1.25em 0.625em 0.5625em;
}
.product_title_dates {
	color: #000;
	display: block;
	font-weight: 500;
}
.product_title_desc {
	font-size: 0.875em;
	margin: 0.5em 0 0;
}
.product_title_price {
	font-size: 0.9286em;
}
.product_title_price_amount {
	color: #000;
}

.product_top {
	display: flex;
	justify-content: space-between;
	padding: 0 0.3125em 0;
}
.product_top .product_brand {
	margin: 0.25em 0 0;
}

.product_user_form {
	padding: 0.7692em;
}
.product_user_form .button.button-less {
	margin: 0 0.25em;
}
.product_user_form_buttons {
	display: flex;
	justify-content: center;
	margin: 1.5385em 0 0;
}
.product_user_form_hd {
	font-size: 0.8175em;
	margin: 0;
	text-align: center;
}
.product_user_form_content {}
.product_user_form_content > li {
	margin: 0.5em 0 0;
}
.product_user_form_content > li:first-child {
	margin-top: 0;
}


.product_variants {
	margin: 0.3125em 0 0;
}
.product_variants-error {
	color: #f00;
	font-size: 0.875em;
	line-height: 1.29;
	margin: 0;
	text-align: center;
}

.product .additional-list {
	margin: 0.5em 0 0;
}


/*--- ProductInCart ---*/
.productInCart {
	display: flex;
}
.productInCart_image {
	width: 40%;
}
.productInCart_image img {
	max-height: 100%;
	max-width: 100%;
}
.productInCart_content {
	width: 60%;
}
.productInCart_content_bottom {
	align-items: baseline;
	display: flex;
	justify-content: space-between;
	margin: 0.5em 0 0;
}
.productInCart_content_desc {
	font-size: 0.8125em;
	margin: 1em 0 0;
}
.productInCart_content_edit {
	color: #359AF8;
	cursor: pointer;
	margin: 0 0 0 0.4em;
}
.productInCart_content_hd {
	font-size: 1.2em;
	margin: 0;
}
.productInCart_content_inner {
	margin: 0 0.5em 0.5em 1em;
}
.productInCart_content_price {
	font-size: 1.5em;
}


/*--- ProductItem ---*/
.productItem {}
.productItem_bottom {
	align-items: center;
	display: flex;
	justify-content: space-between;
	margin: 2em 0 0;
}
.productItem_bottom--priceOnly {
	justify-content: flex-end;
}
.productItem_desc {
	font-size: 0.8em;
	margin: 1em 0 0;
}
.productItem_desc li {
	margin: 0;
}
.productItem_hd {
	cursor: pointer;
}
.productItem_image {
	cursor: pointer;
	max-width: 100%;
}
.productItem_inner {
	display: flex;
	flex-wrap: wrap;
}
.productItem_inner > .col-12 {
	padding-left: 0;
	padding-right: 0;
}
.productItem_inner > figure:first-child {
	padding-left: 0;
}
.productItem_inner > div:last-child {
	padding-right: 0;
}
.productItem_inner > .col-12 .hd-title2 {
	margin: 1em 0 0;
}
.bordered {
	border: 1px solid #01a7e7;
}
.productItem_inner--bordered {
	border: 1px solid #01a7e7;
}
.productItem_inner > .col-12.productItem_prodInfo--bordered {
	padding: 0 1em 0.4em;
}
.productItem_inner--bordered {
	padding: 0.5em;
	padding-right: 0.8em;
}


/*--- ProductSec ---*/
.product-sec {
	background: #d0e7f7;
	border: 6px solid #eee;
	cursor: pointer;
	overflow: hidden;
	padding: 0 0 10px;
	text-align: center;
}

.product-sec-image {
	max-width: 100%;
	transform: scale(1);
	transition: all 250ms linear 0s;
}
.product-sec:hover .product-sec-image {
	transform: scale(1.2);
}

.product-sec-name {
	color: #00244b;
	font-size: 2.1875em;
	font-style: italic;
	padding: 0 0 5px;
	position: relative;
	text-align: center;
	text-transform: uppercase;
}
.product-sec:hover .product-sec-name {
	color: #01a7e7;
}
.product-sec-name:after,
.product-sec-name:before {
	border-top: 2px solid #001a4b;
	bottom: 5px;
	content: '';
	left: 50%;
	margin: 0	0	0	-30px;
	position: absolute;
	width: 60px;
}
.product-sec-name:after {
	bottom: 0;
	margin-left: -40px;
	width: 80px;
}
.product-sec:hover .product-sec-name:after,
.product-sec:hover .product-sec-name:before {
	border-color: #01a7e7;
}


/*--- ProductMeta ---*/
.productMeta .f-search-extended {
	padding: 1em 0;
}
.productMeta_top {
	background: #01a7e7;
	color: #fff;
	padding: 0.3em 0;
}
.productMeta_content {
	padding: 0 35px 0 2em;
	position: relative;
}
.productMeta_edit {
	align-items: center;
	display: flex;
	height: 100%;
	position: absolute;
	right: 0;
	top: 0;
}
.productMeta_edit_icon {
	cursor: pointer;
}
.productMeta_flat {
	background: #f0f0f0;
}


/*--- ProductsPage ---*/
.productPage {}
.productPage .breadcrumbs {
	margin: 1em 0 1.5em;
}

.productPage_bottom {
	align-items: center;
	display: flex;
	justify-content: flex-end;
	margin: 1em 0 2em;
	padding: 0 1em;
}
.productPage_button {
	margin: 1em 0 0;
	text-align: center;
}

.productPage_content > p:first-child {
	margin-top: 0;
}

.productPage_data {
	margin-top: 1em;
}

.productPage_images .notFound {
	margin: 0.7em 0 0;
}

.productPage_modal {
	max-width: 270px;
}
.productPage_modal .plus-list {
	margin: 1em 0 0;
}
.productPage_modal_footer {
	margin: 1.5em 0 0;
	text-align: center;
}
.productPage_modal_price {
	color: #01a7e7;
}

.location_modal {
	min-width: 600px;
}
.location_modal_footer {
	margin: 1.5em 0 0;
	text-align: center;
}

.shoppingPage_modal {
	width: 450px;
}
.shoppingPage_modal_footer {
	margin: 1.5em 0 0;
	text-align: center;
}

.quantity {
	max-width: 200px;
	display: inline;
}

/*--- Products ---*/
.products {
	padding: 2.8125em 0 5.3125em;
}
.products .products-list {
	margin-top: 2.5em;
}

.mt10 {
	margin-top: 10px;
}


/*--- ProductsList ---*/
.products-list {}
.products-list li:first-child {
	margin-top: 0;
}
.products-list .product-sec {
	animation-name: rollIn;
	margin: 0 auto;
}


.productsPage .main-right {
	padding-bottom: 3em;
}
.productsPage .moreResults {
	margin: 2em 0 0;
}
.productsPage .responsiveList {
	margin: 1em 0 0;
}
.mr20 {
	margin-right: 20px;
}


/*--- Ranger ---*/
.ranger {}
.ranger_limit {
	font-size: 0.875em;
	font-weight: 700;
	margin: 0;
	text-align: center;
}
.ranger .rc-slider {
	height: 1.25em;
	padding: 0;
}
.ranger .rc-slider-handle {
	background: #337ab7;
	border: 0 none;
	cursor: pointer;
	height: 100%;
	margin: 0 0 0 -0.625em;
	width: 1.25em;
}
.ranger .rc-slider-handle:active,
.ranger .rc-slider-handle:focus {
	box-shadow: none;
}
.ranger .rc-slider-rail {
	background-image: linear-gradient(to bottom, #f5f5f5 0%, #f9f9f9 100%);
	background-repeat: repeat-x;
	border-radius: 0.25em;
	box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
	cursor: pointer;
	height: 0.625em;
	margin: -0.3125em 0 0;
	top: 50%;
}
.ranger .rc-slider-track {
	background-image: linear-gradient(to bottom, #f9f9f9 0%, #f5f5f5 100%);
	background-repeat: repeat-x;
	box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
	cursor: pointer;
	height: 0.625em;
	margin: -0.3125em 0 0;
	top: 50%;
}

.ranger_range {
	color: #01a7e7;
	font-size: 0.875em;
	margin: 0;
	text-align: center;
}


/*--- ResponsiveList ---*/
.responsiveList {}
.responsiveList > li {
	display: flex;
	margin: 0;
}
.responsiveList > li:first-child {
	border-top: 0 none;
}
.responsiveList.productsSearched > li {
	margin-top: 30px;
}
.responsiveList.productsSearched > li:first-child {
	margin-top: 0;
}
.responsiveList > li > div:first-child {
	padding-left: 0;
}
.responsiveList.responsiveList_multi > li > div:first-child {
	padding-right: 15px;
}
.responsiveList > li > div:last-child {
	padding-right: 0;
}


/*--- Rollet ---*/
.rollet {}
.rollet .looking-in {
	padding-top: 1.665em;
}

.rollet_children {}
.rollet--scrolled .rollet_children {
	border: 1px solid #d6d6d6;
	max-height: 200px;
	overflow-x: hidden;
	overflow-y: scroll;
	padding: 0 1.25em 2.1875em;
}

.rollet_title {
	align-items: center;
	background: #01a7e7;
	color: #fff;
	cursor: pointer;
	display: flex;
	font-size: 1.5em;
	justify-content: space-between;
	min-height: 2em;
	padding: 0 0.4em;
	position: relative;
}
.rollet--scrolled .rollet_title {
	background: #494949;
	font-size: 1.25em;
	height: 2.7em;
	justify-content: center;
	text-transform: uppercase;
	padding: 0;
}


/*--- Select ---*/
.select {
	position: relative;
}

.select.mod1,
.select.small {
	font-size: 0.875em;
}
.select.mod1 .Select,
.select.small .Select {
	font-size: 0.928em;
}
.select.mod1 .Select-arrow {
	border-top-color: #fff !important;
}
.select.mod1 .Select.is-open .Select-arrow {
	border-bottom-color: #fff !important;
}
.select.mod1 .Select-clear,
.select.small .Select-clear {
	font-size: 1.5em;
	line-height: 1.1;
}
.select.mod1 .Select-clear-zone,
.select.mod1 .Select-value-label {
	color: #fff !important;
}
.select.small .Select-control,
.select.small .Select-input {
	height: 2.154em;
}
.select.mod1 .Select-control,
.select.mod1 .Select-input {
	height: 2.31em;
}
.select.small .Select-input > input {
	padding: 0.5em 0 0;
}
.select.mod1 .Select-control {
	background: #01a7e7 !important;
	border: 0 none;
	border-radius: 0;
	color: #fff;
	cursor: pointer;
}
.select.mod1 .Select-placeholder,
.select.mod1 .Select-value {
	color: #fff;
	cursor: pointer;
	font-size: 1.231em;
	line-height: 1.875em !important;
}
.select.small .Select-value {
	line-height: 2.154em !important;
}
.select.small .Select-placeholder {
	line-height: 2.154em;
}
.select-mod1-option {
	color: #333 !important;
	padding-bottom: 0.1em !important;
	padding-top: 0.1em !important;
}


/*--- Service block ---*/
.service {
	background-color: #fff;
	border: 4px solid #f1fcff;
	border-radius: 2px;
	box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.18);
	padding: 2em 1.5625em;
	text-align: center;
	transition: all 0.5s linear 0s;
}
.service:hover {
	background-color: #f1fcff;
	border-color: #f1fcff;
}

.service-circle {
	align-items: center;
	border: 1px dashed #000;
	border-radius: 100%;
	cursor: pointer;
	display: flex;
	height: 180px;
	justify-content: center;
	margin: 0	auto;
	position: relative;
	width: 180px;
}
.service:hover .service-circle {
	border-color: #00365b;
}

.service-description {
	color: #8d8d8d;
	font-size: 0.875em;
	font-weight: 400;
	line-height: 1.47;
	margin-top: 15px;
}

.service-heading {
	color: #14466a;
	font-size: 1.125em;
	font-weight: 300;
	line-height: 1.1;
	margin: 15px 0 0;
}

.service-image {
	height: 80px;
}

.service-number {
	align-items: center;
	background: #01a7e7;
	border: 5px solid #fff;
	border-radius: 100%;
	color: #fff;
	display: flex;
	font-size: 1.75em;
	font-weight: 500;
	height: 54px;
	justify-content: center;
	left: 50%;
	margin: 0 0 0 -27px;
	position: absolute;
	top: -27px;
	width: 54px;
}
.service:hover .service-number {
	border-color: #f1fcff;
}


/*--- SocialBtn ---*/
.social-btn {
	align-items: center;
	background: #fff;
	border-radius: 100%;
	box-shadow: 0 0 0 5px #e8e8e8 inset;
	color: #00365b;
	cursor: pointer;
	display: flex;
	height: 69px;
	justify-content: center;
	transition: all 250ms linear 0s;
	width: 69px;
}
.social-btn:hover {
	box-shadow: 0 0 0 40px #01a7e7 inset;
	color: #fff;
}


.sorter {
	align-items: center;
	display: flex;
}
.sorter .select {
	width: 100%;
}
.sorter_label {
	margin: 0 0.5em 0 0;
	white-space: nowrap;
}



/*--- Status ---*/
.status {
	text-align: center;
}
.status_msg {
	margin: 0;
}


.StripeElement {
	padding-bottom: 0.55em;
	padding-top: 0.55em;
}


/*--- Tip ---*/
.tip {
	position: relative;
}
.tip_child {}

.tip_text {
	display: none;
	justify-content: center;
	position: absolute;
	top: -3em;
	width: 100%;
	z-index: 1;
}
.ranger .tip_text {
	top: -2.3em;
}

.tip_text_message {
	background: #000;
	border-radius: 0.5em;
	color: #fff;
	display: inline-block;
	font-size: 0.75em;
	padding: 0.4em 1em 0.5em;
	position: relative;
}
.tip_text_message:after {
	border: 5px solid #000;
	border-color: #000 transparent transparent;
	bottom: -10px;
	content: '';
	height: 0;
	left: 50%;
	margin: 0 0 0 -4px;
	position: absolute;
	width: 0;
}


.userProfile {
	background: #f0f0f0;
	padding: 113px 15px 0;
	position: relative;
}
.userProfile_avatar {
	display: flex;
	justify-content: center;
	left: 0;
	position: absolute;
	top: -65px;
	width: 100%;
}
.userProfile_bottom {
	background: #dcdcdc;
	margin: 25px -15px 0;
	padding: 20px 15px 10px;
}
.userProfile_btn {
	margin: 20px 0 0;
	white-space: nowrap;
}
.userProfile_btn_status {
	align-items: center;
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.userProfile_fields > .col-12 {
	margin-top: 1.5625em;
}
.userProfile_fields > .col-12:first-child {
	margin-top: 0.5em;
}


.userProfilePage {
	position: relative;
}
.userProfilePage .userProfile {
	margin-top: 1.25em;
}
.userProfilePage_status {
	position: absolute;
	top: 1.5em;
}
.userProfilePage_updateCard_hd {
	margin: 0 0 1em;
	text-align: center;
}


.withCamera {
	border-radius: 50%;
	display: inline-block;
	position: relative;
}
.withCamera .fileInput {
	bottom: -18px;
	left: 50%;
	margin: 0 0 0 -19px;
	position: absolute;
}
.withCamera .imageRound {
	display: block;
}
.withCamera_icon {
	align-content: center;
	background: #01a7e7;
	border-radius: 50%;
	color: #fff;
	display: flex;
	height: 39px;
	justify-content: center;
	width: 39px;

	overflow: hidden;
}


/*--- WorkFloat ---*/
.workfloat {
	background: url('../images/how_works_bg.jpg') no-repeat 50% 50%;
	background-size: cover;
	padding: 2.8125em 0 3.4375em;
}


/*--- WorkFloatList ---*/
.workfloat-list {}
.workfloat-list li:first-child {
	margin-top: 0;
}
.workfloat .workfloat-list {
	margin-top: 2.5em;
}
.workfloat-list .service {
	animation-name: rollIn;
	height: 100%;
	margin: 0 auto;
	max-width: 85%;
	transition: all 0.5s linear 0s;
}


@media (min-width: 2050px) and (max-width: 2732px) {
	.carousel {
		max-height: 1080px;
	}
}


@media (min-width: 1709px) and (max-width: 2049px) {
	.carousel {
		max-height: 900px;
	}
}


@media (min-width: 1526px) and (max-width: 1708px) {
	.carousel {
		max-height: 740px;
	}
}


@media (min-width: 1367px) and (max-width: 1525px) {
	.carousel {
		max-height: 672px;
	}
}


@media screen and ( min-width: 1200px) {
	.product-sec {
		min-height: 480px;
	}
}


@media screen and ( max-width: 1199px) {
	.footer {
		background-size: 120% auto;
	}

	.inner-banner_hd {
		font-size: 1.125em;
	}

	.point {
		font-size: 0.8125em;
	}

	.product-sec {
		min-height: 400px;
	}
	.service-description {
		font-size: 0.75em;
	}
}


@media screen and ( max-width: 991px) {
	.header .basket_icon,
	.header .notifications,
	.header-info-btn .imageRound {
		height: 40px !important;
		width: 40px !important;
	}
	.header .button {
		font-size: 0.875em;
		height: 40px;
	}
	.header .logo {
		max-width: 183px;
	}
	.header .notifications {}

	.header-info .header-info-basket {
		margin-right: 0.933em;
		padding-right: 0.667em;
	}

	.product-sec {
		min-height: 300px;
	}
	.product-sec-name {
		font-size: 1.5em;
	}

	.service {
		padding: 1.5625em 0.625em;
	}
	.service-circle {
		height: 140px;
		width: 140px;
	}

	.workfloat-list .service {
		max-width: 100%;
	}
}


@media screen and ( max-width: 767px) {
	.f-search-extended--mt {
		margin-top: 1.25em;
	}

	.header-info .header-info-basket {
		border-right: 0 none;
		margin-right: 0.4em;
		padding-right: 0;
	}

	.inner-banner_cnt {
		min-height: 4em;
		justify-content: center;
	}
	.inner-page_search {
		padding-left: 15px;
		padding-right: 15px;
	}
	.inner-page_search .f-search2 {
		width: 100%;
	}

	.main-right {
		margin-top: 2em;
	}

	.p-description {
		font-size: 0.875em;
		line-height: 2;
	}

	.point {
		min-height: inherit;
	}

	.point-list li {
		margin-top: 1.25em;
	}
	.point-list .point {
		max-width: 300px;
	}

	.productPage_content {
		font-size: 0.8em;
	}

	.product-sec {
		min-height: inherit;
	}

	.products-list li {
		margin-top: 1.875em;
	}
	.products-list .product-sec {
		max-width: 380px;
	}

	.userProfilePage .userProfile {
		margin-top: 4em;
	}

	.workfloat-list li {
		margin-top: 1.875em;
	}
	.workfloat-list .service {
		max-width: 85%;
	}
}
@media screen and ( min-width: 767px) {
	.userProfile_bottom {
		display: flex;
		justify-content: space-between;
		padding-bottom: 20px;
	}
	.userProfile_btn_wrap {
		margin-top: 0 !important;
	}

	.userProfile_fields > .col-12:first-child + .col-sm-6 {
		margin-top: 0.5em;
	}
	.userProfile_fields > .col-md-4 {
		margin-top: 0.5em;
	}
}


@media screen and ( max-width: 639px) {
	.f-search-main__button {
		height: 2.1em;
	}
	.f-search-main__button:before {
		bottom: 0.5em;
		top: 0.5em;
	}
	.f-search-main__input {
		font-size: 0.8em;
		height: 2.5em;
	}

	.footer {
		background-size: 150% auto;
	}

	.header .basket_icon {
		height: 36px;
		width: 36px;
	}
	.header .button {
		height: 36px;
	}
	.header-info {
		float: none;
		justify-content: center;
		margin-top: 30px;
	}
	.header .logo {
		max-width: 281px;
	}
	.header .logo-cnt {
		display: block;
		float: none;
		text-align: center;
	}

	.main-offer__description {
		font-size: 1em;
	}
	.main-offer__heading {
		font-size: 2em;
	}
	.main-offer__options {
		font-size: 0.85em;
		margin-top: 0.6em;
	}
	.main-offer__options li {
		margin: 0;
	}

	.mob_info .f-search-main {
		margin-top: 0.7em;
	}
}


@media screen and ( max-width: 575px) {
	.productPage_content {
		margin-top: 1em;
	}
}


@media screen and ( min-width: 576px) {
	.cartPage_buttons {
		text-align: left;
	}
	.cartPage_buttons > .row > .col-12:first-child {
		text-align: right;
	}

	.cartPage_cnt {
		padding-left: 15px;
		padding-right: 15px;
	}
	.confirmForm_product_sec .fields_set {
		margin: 0 0 0 2em;
	}
	.confirmForm_product_sec .fields_set > .fields_set_fields:first-child {
		padding-top: 0;
	}

	.fDelivery > .row > .col-12 {
		margin-top: 0;
	}

	.manageResults > .row > div {
		align-items: center;
		display: flex;
	}
	.manageResults_centre {
		border: 1px solid #000;
		border-bottom: 0 none;
		border-top: 0 none;
	}
	.manageResults_centre--noFilter {
		border-right: 0 none;
		padding-right: 0;
	}
}


@media screen and ( max-width: 479px) {
	.footer {
		background-size: cover;
	}

	.info-list {
		display: block;
	}
	.info-list li {
		border-left: 0 none;
		margin-left: 0;
		margin-top: 1.071em;
		padding-left: 0;
	}
	.info-list li:first-child {
		margin-top: 0;
	}

	.price {
		font-size: 1.3em;
	}
	.productItem .hd-title2 {
		font-size: 1em;
	}

	.social-btn {
		height: 50px;
		width: 50px;
	}
}


@media screen and ( max-width: 430px) {
	.mob_info {
		display: block;
		top: 1em;
	}

	.productMeta_top {
		padding-bottom: 0.2em;
		padding-top: 0.2em;
	}
	.productMeta_content {
		font-size: 0.75em;
		padding-left: 0;
	}
}


@media screen and (max-width: 379px) {
	.product-sec {
		min-width: inherit;
	}
}

@media screen and (min-width: 379px) {
	.userProfile_btn_wrap {
		display: flex;
		margin: 20px 0 0;
	}
	.userProfile_btn_wrap .userProfile_btn {
		margin: 0 0 0 20px;
	}
	.userProfile_btn_wrap .userProfile_btn:first-child {
		margin-left: 0;
	}
}

.m-auto {
	margin: auto;
}

.loader {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: white;
	z-index: 9999;
  }

.mt-10 {
	margin-top: 10px;
}

.mt-20 {
	margin-top: 20px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-20 {
	margin-bottom: 20px;
}
